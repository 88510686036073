// @file Composable to copy link to clipboard
// Use with <GlobalSnackbar/> for visual feedback on copy actions
import { copyToClipboard as bitsCopyToClipboard } from '@@/bits/clipboard'
import { __ } from '@@/bits/intl'
import type { GlobalSnackbarNotification } from '@@/pinia/global_snackbar'
import { SnackbarNotificationType, useGlobalSnackbarStore } from '@@/pinia/global_snackbar'

export const useCopyToClipboard = (): {
  copyToClipboard: typeof copyToClipboard
} => {
  const copyToClipboard = async ({
    text,
    globalSnackbarOptions,
  }: {
    text: string
    globalSnackbarOptions: GlobalSnackbarNotification
  }): Promise<void> => {
    const globalSnackbarStore = useGlobalSnackbarStore()
    const isLinkCopySuccess = await bitsCopyToClipboard(text)

    if (!isLinkCopySuccess) {
      globalSnackbarStore.setSnackbar({
        message: __('Failed to copy link to clipboard'),
        notificationType: SnackbarNotificationType.error,
        timeout: 1500,
      })
    } else {
      globalSnackbarStore.setSnackbar(globalSnackbarOptions)
    }
  }

  return {
    copyToClipboard,
  }
}
